import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private subscriptionData = new BehaviorSubject<{ success: boolean; data: any } | null>(null);

  // Observable for subscription data
  subscriptionData$ = this.subscriptionData.asObservable();

  /**
   * Updates the subscription data
   * @param data The data to be shared
   */
  updateSubscriptionData(data: { success: boolean; data: any }) {
    this.subscriptionData.next(data);
  }
}
